const BRIEF_APPS = [
    {
        id: "ada",
        name: "ada.",
        description: {
            br: "Meu TCC do curso de bacharel em Ciência da Computação. É uma aplicação que visa tornar o dia a dia do usuário mais eficiênte, facilitando a execução de algumas tarefas.",
            en: "My undergraduate thesis from my bachelor in Computer Science. It's a app that intends to turn the user's day more efficient, making the execution of some tasks much easier."
        },
        animationName: "ada.json",
        tools: ["javascript", "html", "css", "python", "electron", "sqlite"]
    },
    {
        id: "usersio",
        name: "users.io",
        description: {
            br: "Um projeto que fiz para praticar Express e React. É tudo bem simples, criei alguns endpoints para acessar as APIs de usuários e repositórios do GitHub. Tudo bem simples, só pra praticar mesmo.",
            en: "A project that I did for pratice Express and React. It's a simple website that uses Github Users API, I create some endpoints that consume it."
        },
        animationName: "usersio.json",
        tools: ["javascript", "html", "css", "react", "express"]
    },
    {
        id: "yobel",
        name: "Yobel",
        description: {
            br: "Meu primeiro trabalho como desenvolvedor. Um site que permite ao usuário montar seu ovo de páscoa perfeito e depois o encomendar para a empresa.",
            en: "My first job as a developer. It's a website from a local business that sells chocolate eggs, a very common tradition in Brazil."
        },
        animationName: "yobel.json",
        tools: ["javascript", "html", "css", "react", "firebase"]
    },
    {
        id: "istudent",
        name: "iStudent",
        description: {
            br: "Meu TCC do curso técnico em Informática. Um aplicativo ajuda o estudante a organizar sua vida escolar.",
            en: "My 'undergraduate thesis' from my technician course in Software Development. It's an app that helps the user organize their school life."
        },
        animationName: "istudent.json",
        tools: ["delphi", "sqlite"]
    }
]

export default BRIEF_APPS