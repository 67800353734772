const IMAGES = "/images"

const BRIEF_APPS_IMGS = `${IMAGES}/briefapps`
const APPS_IMGS = `${IMAGES}/apps`
const VIDEOS_IMGS = `${IMAGES}/videos`
const DESIGN_IMGS = `${IMAGES}/design`
const CERTFICADES_IMGS = `${IMAGES}/certificades`
const CERTFICADES_MINIATURE = `${CERTFICADES_IMGS}/miniature`
const CERTFICADES_FULL = `${CERTFICADES_IMGS}/full`

export { IMAGES, BRIEF_APPS_IMGS, APPS_IMGS, CERTFICADES_IMGS, CERTFICADES_MINIATURE, 
    CERTFICADES_FULL, VIDEOS_IMGS, DESIGN_IMGS }