const texts = {
  en: {
    salute: "hi!",
    myName: "my name is",
    adjectives: [
      "developer",
      '"designer"',
      "little too myopic",
      "music lover",
      "little clumsy",
      "terrible singer",
      "blink",
      '"reader"'
    ],
    iAm: "I am a",
    aboutMe: "about me"
  },
  br: {
    salute: "oi!",
    myName: "meu nome é",
    adjectives: [
      "desenvolvedor",
      '"designer"',
      "pouco muito míope",
      "amante de música",
      "pouco desastrado",
      "péssimo cantor",
      "blink",
      '"leitor"'
    ],
    iAm: "eu sou um",
    aboutMe: "sobre mim"
  }
};

export default texts; 