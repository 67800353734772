const squares = [
    "rifa.jpg",
    "bSticker.jpg"
]

const panoramas = [
    "jdBanner.jpg"
]

const portraits = [
    "ioBanner.jpg"
]

export { squares, panoramas, portraits }