const PERSONAL_INFO = {
    name: "Guilherme H. Scarpel",
    email: "ghs.scarpel@outlook.com",
    phone: "+55 35 9 9114-3705",
    location: "Brasil",
    social: [
        ["github", "https://github.com/scarpel/"],
        ["linkedin", "https://www.linkedin.com/in/scarpel/"],
        ["insta", "https://www.instagram.com/gui.scarpel"]
    ],
    resume: {
        en: "/Resume.pdf",
        br: "/ResumeBR.pdf"
    }
}

export default PERSONAL_INFO