const texts = ({
  en:{
    pageName: "contact",
    lets: "let's work",
    together: "together?!",
    phone: "phone",
    resume: "resume"
  },
  br:{
    pageName: "contato",
    lets: "vamos trabalhar",
    together: "juntos?!",
    phone: "telefone",
    resume: "currículo"
  }
});

export default texts;