const videos = [
    {
        img: "smartContracts.png",
        name: "Smart Contracts",
        description: "Um vídeo que editei para um trabalho da faculdade da disciplina de Segurança da Informação sobre Smart Contracts.",
        link: "https://youtu.be/fe1vkv6mZRk",
        duration: "23:11",
        tools: ["afterEffects"]
    },
    {
        img: "livroVsSpoilers.jpg",
        name: "Abertura de Canal",
        description: "Um abertura que fiz para o meu antigo, e vergonhoso, canal de YouTube sobre livros.",
        link: "https://youtu.be/1YfxrK45qSk",
        duration: "00:06",
        tools: ["afterEffects"]
    }
]

export default videos