const language = {
  en: {
    pageName: "jobs",
    galery: "galery"
  },
  br: {
    pageName: "trabalhos",
    galery: "galeria"
  }
};

export default language;