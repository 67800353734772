const texts = {
  en: {
    pageName: "skills",
    disclaimer: "The more opaque, the more time since I last used it",
    others: "others"
  },
  br: {
    pageName: "habilidades",
    disclaimer: "Quanto mais opaca, mais tempo faz desde a última vez que a utilizei",
    others: "outros"
  }
};

export default texts;